
import { defineComponent, ref } from 'vue';
import lxCrud from '@/components/Crud/index.vue';
// import Ellipsis from '@/components/Ellipsis/index.vue';
import { useRouter } from 'vue-router';
import { useCrud } from '@/modules/personnel/testStageBug/crud.opt';
import { getBugScoreTips } from '@/api';
import { Message } from '@/common/utils';
import { InWorkUserBugOverview } from './type';

export default defineComponent({
  name: 'TestStageBug',
  components: {
    lxCrud,
  },
  setup() {
    // 获取crud配置详情
    const { options } = useCrud();

    // 路由实例
    const router = useRouter();

    // 跳转
    const goTask = (id: number) => {
      window.open(router.resolve({ name: 'TaskBug', query: { id } }).href);
    };

    // 转换bug概览
    const loadBugsOverview = (row: InWorkUserBugOverview) => {
      let overviews = '';
      row.work_user_bugs.forEach((el: any, index: number) => {
        overviews += `${el.stageLabel} - `;
        el.levelLabel && (overviews += `${el.levelLabel} - `);
        overviews += `${el.convertCount}`;
        (index < (row.work_user_bugs.length - 1)) && (overviews += '、');
      });
      return overviews;
    };

    /**
     * bug_score 提示模块
     */
    const tipsControl = ref({ visible: false, content: '' });
    // 获取bug_score提示文本
    const getTipsContent = async() => {
      const { code, data } = await getBugScoreTips();
      if (code === 0) {
        tipsControl.value.content = data.bug_score_tip.replace(/\n/g, '<br/>');
        return;
      }
      Message('获取提示文本失败', 'error');
    };

    // 打开bug_score提示文本
    const openTipsPopup = async () => {
      !tipsControl.value.content && await getTipsContent();
      tipsControl.value.visible = tipsControl.value.content ? !tipsControl.value.visible : false;
    };

    return {
      options,
      loadBugsOverview,
      goTask,
      tipsControl,
      openTipsPopup,
    };
  },
});
