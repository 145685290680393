import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_question_filled = _resolveComponent("question-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_lx_crud = _resolveComponent("lx-crud")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_lx_crud, {
      ref: "crud",
      options: _ctx.options,
      immediate: "",
      pagination: ""
    }, {
      work_user_bugsTableSlot: _withCtx((scope) => [
        (scope.row.is_no_bug)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, " 无bug "))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(scope.row.work_user_bugs.length === 0 ? '--' : _ctx.loadBugsOverview(scope.row)), 1))
      ]),
      work_nameTableSlot: _withCtx((scope) => [
        _createVNode(_component_el_button, {
          type: "text",
          disabled: !scope.row.work_id,
          onClick: ($event: any) => (_ctx.goTask(scope.row.work_id))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(scope.row.work_name), 1)
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ]),
      degree_of_difficultTableSlot: _withCtx((scope) => [
        _createElementVNode("div", null, _toDisplayString(scope.row.degree_of_difficult ? `${scope.row.degree_of_difficult / 10}` : '--'), 1)
      ]),
      bug_scoreTableSlotHeader: _withCtx(() => [
        _createVNode(_component_el_popover, {
          visible: _ctx.tipsControl.visible,
          "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tipsControl.visible) = $event)),
          placement: "top",
          width: 400,
          trigger: "manual"
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", null, [
              _cache[1] || (_cache[1] = _createTextVNode(" bug_score ")),
              _createVNode(_component_el_icon, {
                size: 15,
                class: "can-pointer",
                onClick: _ctx.openTipsPopup
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_question_filled)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.tipsControl.content
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["visible"])
      ]),
      bug_scoreTableSlot: _withCtx((scope) => [
        _createElementVNode("div", null, _toDisplayString(scope.row.bug_score ? `${scope.row.bug_score}（${scope.row.bug_score_kpi}）` : '--'), 1)
      ]),
      _: 1
    }, 8, ["options"])
  ]))
}